<template>
  <div class="view-container">
    <div class="card qgroup-table">
      <div class="card-header card-header-divider">
        <div class="card-title">Группы вопросов</div>
        <div class="tools">
          <router-link to="/test/question-group/edit" class="btn btn-accent"
            >Добавить</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <question-group-table />
      </div>
    </div>
  </div>
</template>

<script>
import QuestionGroupTable from "../../feature/testing/tables/QuestionGroupTable.vue";
export default {
  components: { QuestionGroupTable },
  name: "questiongroup-page",
};
</script>

<style>
</style>