<template>
  <div>
    <v-pagination
        v-model="page"
        @update:modelValue="changePage"
        :pages="Math.ceil(total / countOnPage)"
        :range-size="1"
        active-color="transparent"
    />

    <div v-if="total" class="pagination-info">
      <p>
        Показаны строки с {{ countOnPage * (page - 1) + 1 }} по
        {{
          countOnPage * (page - 1) + count
        }}
        из
        {{ total }}
      </p>
      <p>
        Страница {{ page }} из
        {{
          Math.ceil(total / countOnPage)
        }}
      </p>
    </div>
    <div class="pagination-info" v-else>Нет результатов</div>
  </div>

</template>

<script>
import {ref, toRef} from "@vue/reactivity";
import {watch} from "@vue/runtime-core";
import VPagination from "@hennge/vue3-pagination";
import MiniPreloader from "@/components/Technical/MiniPreloader.vue";

export default {
  name: "table-pagination",
  components: {
    MiniPreloader,
    VPagination,
  },
  props: {
    countOnPage: {
      required: true,
    },
    activeFilter: {
      required: false,
    },
    total: {
      type: Number,
      required: false,
    },
    count: {
      type: Number,
      required: false,
    },
    startPage: {
      type: Number,
      required: false,
    }
  },
  setup(props, context) {
    const page = ref(0),
        countOnPage = toRef(props, "countOnPage"),
        rowCount = ref(null),
        allRow = ref(null),
        isPreloaderLoad = ref(false)

    page.value = props.startPage ?? 1;
    const changePage = (page) => {
      context.emit("changePage", page);
    };

    watch(countOnPage, () => {
      page.value = 1;
    });

    return {
      page,
      changePage,
      rowCount,
      allRow,
      isPreloaderLoad
    };
  },
};
</script>

<style>
</style>